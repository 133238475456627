import React, { useCallback, useRef } from "react"
import { graphql } from "gatsby"
import { useMediaQueries } from "@react-hook/media-query"
import { Col, FormGroup, Input, Label, Row } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { GoogleReCaptcha, useGoogleReCaptcha } from "react-google-recaptcha-v3"
import querystring from "querystring"

import PrimaryButton from "../ui/primary-button"
import RenderBlock from "../ui/block-text"

const Contact = ({ id, title, description, social }) => {
  const formInstance = useRef()
  const {
    matches: { isMobile },
  } = useMediaQueries({
    isMobile: "only screen and (max-width: 992px)",
  })
  const { executeRecaptcha } = useGoogleReCaptcha()

  const RenderSocial = () => {
    return social?.map(item => (
      <a href={item.link} target={item.target} key={item._key}>
        {item.text === "instagram" && (
          <FontAwesomeIcon
            icon={["fab", "instagram"]}
            className="social-icon"
          />
        )}
        {item.text === "facebook" && (
          <FontAwesomeIcon icon={["fab", "facebook"]} className="social-icon" />
        )}
        {item.text === "twitter" && (
          <FontAwesomeIcon icon={["fab", "twitter"]} className="social-icon" />
        )}
        {item.text === "linkedin" && (
          <FontAwesomeIcon
            icon={["fab", "linkedin-in"]}
            className="social-icon"
          />
        )}
        {item.text === "github" && (
          <FontAwesomeIcon icon={["fab", "github"]} className="social-icon" />
        )}
      </a>
    ))
  }
  const handleSubmit = useCallback(
    async event => {
      event.preventDefault()
      if (!executeRecaptcha) {
        return false
      }
      const action = "contact_us"
      const result = await executeRecaptcha(action)
      if (result) {
        const formData = new FormData(formInstance.current)
        formData.append("to", "javier.gomez@redvalley.com")
        formData.append("redirect", "https://www.rvdev.com/")
        formData.append("site", "https://www.rvdev.com/")
        formData.append("token", result)
        formData.append("key", process.env.GATSBY_GOGGLE_RECAPTCHA_SECRET_KEY)
        formData.append("action", action)
        let object = {}
        formData.forEach((value, key) => {
          object = { ...object, [key]: value }
        })
        const query = querystring.stringify(object)
        fetch(
          "https://l6kg4l50gi.execute-api.us-east-1.amazonaws.com/send-forms",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
            body: query,
          }
        )
      }
    },
    [executeRecaptcha]
  )

  return (
    <section className="contact" id={id}>
      <div className="container">
        <Row className="mx-0 px-4">
          <Col
            sm="12"
            md="12"
            lg="6"
            xl="6"
            className="d-flex align-items-center justify-content-center"
          >
            <div>
              <RenderBlock data={title} line />
              <RenderBlock data={description} />
              {!isMobile && (
                <div className="tabs social">
                  <RenderSocial />
                </div>
              )}
            </div>
          </Col>
          <Col
            sm="12"
            md="12"
            lg="6"
            xl="6"
            className="d-flex align-items-center justify-content-center"
          >
            <form
              ref={formInstance}
              className="form-contact"
              onSubmit={handleSubmit}
            >
              <FormGroup>
                <Label for="name" hidden>
                  Name
                </Label>
                <Input type="text" name="name" id="name" placeholder="Name" />
              </FormGroup>
              <FormGroup>
                <Label for="email" hidden>
                  Password
                </Label>
                <Input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Email"
                />
              </FormGroup>
              <FormGroup>
                <Label for="message" hidden>
                  Message
                </Label>
                <Input
                  type="text"
                  name="message"
                  id="message"
                  placeholder="Your Message"
                />
              </FormGroup>
              <PrimaryButton
                text="Get in touch"
                alwaysPrimary
                typeBtn="submit"
              />
              <GoogleReCaptcha />
            </form>
          </Col>
        </Row>
      </div>
    </section>
  )
}

export default Contact

export const ContactSection = graphql`
  fragment ContactSection on SanitySectionContact {
    enable
    _rawTitle
    _rawDescription
    redes {
      _key
      type
      target
      text
      link
      enable
      className
    }
  }
`
