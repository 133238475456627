import React from "react"
import { useMediaQueries } from "@react-hook/media-query"
import { graphql } from "gatsby"
import { Col, Row } from "reactstrap"
import classNames from "classnames"

import RenderBlock from "../ui/block-text"
import Image from "../ui/image"

const BannerSuccess = ({ items }) => {
  const {
    matches: { isMobile },
  } = useMediaQueries({
    isMobile: "only screen and (max-width: 992px)",
  })

  const RenderSection = () =>
    items?.map(item =>
      item.position === "left" ? (
        <section
          className={classNames({
            "banner-success": true,
            "banner-success-white": item.style === "light",
          })}
          key={item._key}
        >
          <div className="container">
            <Row className="mx-0">
              {isMobile && (
                <Col sm="12" className="px-0">
                  <Image data={item._rawImage} className="w-100 img-fluid" />
                </Col>
              )}
              <Col
                sm="12"
                md="12"
                lg="4"
                xl="4"
                className="d-flex align-items-center"
              >
                <div className="px-4">
                  <RenderBlock data={item._rawTitle} />
                  <RenderBlock data={item._rawExcerpt} />
                </div>
              </Col>
              {!isMobile && (
                <Col sm="12" md="12" lg="8" xl="8">
                  <Image data={item._rawImage} className="w-100 img-fluid" />
                </Col>
              )}
            </Row>
          </div>
        </section>
      ) : (
        <section
          className={classNames({
            "banner-success": true,
            "banner-success-white": item.style === "light",
          })}
          key={item._key}
        >
          <div className="container">
            <Row className="mx-0">
              <Col
                sm="12"
                md="12"
                lg="8"
                xl="8"
                className="visible-xs visible-sm px-0"
              >
                <Image data={item._rawImage} className="w-100 img-fluid" />
              </Col>
              <Col
                sm="12"
                md="12"
                lg="4"
                xl="4"
                className="d-flex align-items-center"
              >
                <div className="px-4">
                  <RenderBlock data={item._rawTitle} />
                  <RenderBlock data={item._rawExcerpt} />
                </div>
              </Col>
            </Row>
          </div>
        </section>
      )
    )

  return <RenderSection />
}

export default BannerSuccess

export const BannerSuccessSection = graphql`
  fragment BannerSuccessSection on SanitySectionBannerSuccess {
    enable
    items {
      _key
      _rawTitle
      _rawImage
      _rawExcerpt
      enable
      style
      position
    }
  }
`
