import React from "react"
import BlockContent, {
  defaultSerializers,
} from "@sanity/block-content-to-react"

const BlockText = ({
  data,
  hasContainer = true,
  className = "",
  line = false,
}) => {
  const serializers = {
    ...defaultSerializers,
    types: {
      undefined: props => (
        <>
          {" "}
          {props.children} {line && <span className="line" />}{" "}
        </>
      ),
      block: ({ children, node: { style: Tag } }) => {
        if (Tag === "normal") {
          Tag = "p"
        }
        if (!hasContainer) {
          return (
            <>
              {children} {line && <span className="line" />}
            </>
          )
        }
        return (
          <Tag className={className}>
            {children} {line && <span className="line" />}
          </Tag>
        )
      },
    },
    container: props => (
      <>
        {" "}
        {props.children} {line && <span className="line" />}
      </>
    ),
  }

  return <>{data && <BlockContent blocks={data} serializers={serializers} />}</>
}

export default BlockText
