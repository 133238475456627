import React from "react"
import { graphql } from "gatsby"
import { Col, Row } from "reactstrap"

import RenderBlock from "../ui/block-text"
import Image from "../ui/image"

const Testimonials = ({ id, title, items }) => {
  const RenderItems = () => {
    return items?.map(item => (
      <Col xs="12" key={item._key}>
        <Row>
          <Col xs="4" className="d-flex justify-content-center">
            <Image data={item._rawThumbnail} className="avatar" />
          </Col>
          <Col xs="8">
            <RenderBlock data={item._rawExcerpt} className="description" />
            <RenderBlock data={item._rawTitle} className="author" />
            <RenderBlock data={item._rawCharge} className="ocupation" />
          </Col>
        </Row>
      </Col>
    ))
  }

  return (
    <section className="clients" id={id}>
      <div className="container">
        <Row className="mx-0 row-clients">
          <Col
            sm="12"
            md="12"
            lg="6"
            xl="7"
            className="d-flex align-items-center"
          >
            <RenderBlock data={title} line />
          </Col>
          <Col
            sm="12"
            md="12"
            lg="6"
            xl="4"
            className="d-flex align-items-center justify-content-center"
          >
            <Row>
              <RenderItems />
            </Row>
          </Col>
        </Row>
      </div>
    </section>
  )
}

export default Testimonials

export const TestimonialsSection = graphql`
  fragment TestimonialsSection on SanitySectionTestimonials {
    enable
    _rawTitle
    items {
      enable
      _key
      _rawCharge
      _rawTitle
      _rawThumbnail
      _rawExcerpt
    }
  }
`
