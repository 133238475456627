import React from "react"
import { graphql } from "gatsby"

import "../graphql/main-fragments"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Hero from "../components/hero"
import Deparments from "../components/deparments"
import Project from "../components/project"
import BannerSuccess from "../components/banner-success"
import OurTeams from "../components/our-teams"
import Testimonials from "../components/testimonials"
import Blog from "../components/blog"
import Contact from "../components/contact"

import "../graphql/home"

const IndexPage = ({ data }) => {
  const {
    sanityPageHome: {
      title: pageTitle,
      tabs: {
        hero,
        departments,
        projects,
        bannerSuccess,
        ourTeams,
        testimonials,
        blog,
        contact,
      },
    },
  } = data

  return (
    <Layout social={contact?.redes}>
      <SEO title={pageTitle} />
      {hero?.enable && (
        <Hero
          id="home"
          title={hero?.banner._rawTitle}
          description={hero?.banner._rawExcerpt}
          cta={hero?.banner.cta}
          videoWeb={hero?.banner.videoWeb}
          videoMobile={hero?.banner.videoMobile}
        />
      )}
      {departments?.enable && (
        <Deparments id="our-services" items={departments?.items} />
      )}
      {projects?.enable && (
        <Project
          id="creations"
          title={projects?._rawTitle}
          items={projects?.items}
        />
      )}
      {bannerSuccess?.enable && <BannerSuccess items={bannerSuccess?.items} />}
      {ourTeams?.enable && (
        <OurTeams
          id="about"
          title={ourTeams?._rawTitle}
          items={ourTeams?.items}
        />
      )}
      {testimonials?.enable && (
        <Testimonials
          id="testimonials"
          title={testimonials?._rawTitle}
          items={testimonials?.items}
        />
      )}
      {blog?.enable && (
        <Blog id="blog" title={blog?._rawTitle} items={blog?.items} />
      )}
      {contact?.enable && (
        <Contact
          id="contact"
          title={contact?._rawTitle}
          description={contact?._rawDescription}
          social={contact?.redes}
        />
      )}
    </Layout>
  )
}

export default IndexPage

export const IndexQuery = graphql`
  query HomeQuery {
    sanityPageHome(slug: { current: { eq: "home" } }) {
      ...IndexParts
    }
    sanityAppearance(title: { eq: "RV Dev" }) {
      ...appearanceComponent
    }
  }
`
