import React from "react"
import { graphql } from "gatsby"
import { Col, Row } from "reactstrap"

import RenderBlock from "../ui/block-text"
import Image from "../ui/image"

import Slider from "react-slick"
import { useMediaQueries } from "@react-hook/media-query"

const Blog = ({ id, title, items }) => {
  const renderCards = () => {
    return items?.map(item => (
      <div className="slider-item" key={item._key}>
        <Image data={item._rawThumbnail} className="w-100 img-fluid" />
        <RenderBlock data={item?._rawTitle} line />
        <RenderBlock data={item?._rawExcerpt} line className="text" />
      </div>
    ))
  }

  const {
    matches: { mobileSlide, tabletSlide },
  } = useMediaQueries({
    mobileSlide: "only screen and (max-width: 580px)",
    tabletSlide: "only screen and (max-width: 850px) and (min-width: 581px) ",
  })

  const slidesQuantity = (mobileSlide && 1) || (tabletSlide && 2) || 3

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 700,
    slidesToShow: slidesQuantity,
    slidesToScroll: slidesQuantity,
  }

  return (
    <section className="blog" id={id}>
      <div className="container">
        <Row className="mx-0">
          <Col sm="12">
            <RenderBlock data={title} line />
          </Col>
        </Row>
        <Slider {...settings} className="slider-container">
          {renderCards()}
        </Slider>
      </div>
    </section>
  )
}

export default Blog

export const BlogSection = graphql`
  fragment BlogSection on SanitySectionBlog {
    enable
    _rawTitle
    items {
      enable
      _rawTitle
      _rawThumbnail
      _rawExcerpt
      _key
    }
  }
`
