import React from "react"
import { graphql } from "gatsby"
import { Col, Row } from "reactstrap"
import classNames from "classnames"

import RenderBlock from "../ui/block-text"

const Deparments = ({ id, items }) => {
  const RenderItems = () => {
    return items?.map(
      (department, position) =>
        department?.enable &&
        (position === 0 ? (
          <Col sm="12" key={department._key}>
            <div className="service-text">
              <RenderBlock data={department._rawNumber} className="number" />
              <RenderBlock
                data={department._rawTitle}
                className="service"
                line
              />
            </div>
          </Col>
        ) : (
          <Col
            sm="12"
            md="12"
            lg="7"
            xl="7"
            className={classNames({
              "col-inline": position % 2,
            })}
            key={department._key}
          >
            <div className="service-text">
              <RenderBlock data={department._rawNumber} className="number" />
              <RenderBlock
                data={department._rawTitle}
                className="service"
                line={true}
              />
            </div>
          </Col>
        ))
    )
  }

  return (
    <section className="services" id={id}>
      <div className="container">
        <Row className="mx-0">
          <RenderItems />
        </Row>
      </div>
    </section>
  )
}

export default Deparments

export const DepartmentsSection = graphql`
  fragment DepartmentsSection on SanitySectionDepartments {
    enable
    items {
      enable
      _key
      _rawTitle
      _rawNumber
    }
  }
`
