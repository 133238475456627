import React from "react"
import { useMediaQueries } from "@react-hook/media-query"
import { graphql } from "gatsby"
import { Col, Row } from "reactstrap"

import RenderBlock from "../ui/block-text"
import Image from "../ui/image"

const Project = ({ id, title, items }) => {
  const {
    matches: { isMobile },
  } = useMediaQueries({
    isMobile: "only screen and (max-width: 992px)",
  })

  const RenderItems = () => {
    return items?.map(item => (
      <Col sm="12" md="12" lg="4" xl="4" key={item._key}>
        <div className="img-container">
          <Image data={item._rawThumbnail} className="img-fluid w-100" />
          {!isMobile && (
            <div className="text">
              <RenderBlock data={item._rawTitle} className="service" line />
            </div>
          )}
        </div>
      </Col>
    ))
  }

  return (
    <section className="creations" id={id}>
      <div className="container">
        <Row className="mx-0">
          <Col sm="12">
            <RenderBlock data={title} className="service" line />
          </Col>
          <RenderItems />
        </Row>
      </div>
    </section>
  )
}

export default Project

export const ProjectSection = graphql`
  fragment ProjectSection on SanitySectionProjects {
    enable
    _rawTitle
    items {
      enable
      _key
      _rawTitle
      _rawThumbnail
    }
  }
`
