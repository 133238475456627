import React from "react"
import { Link } from "gatsby"
import AnchorLink from "react-anchor-link-smooth-scroll"
const Anchor = ({
  type = "link",
  link,
  text,
  target = "_self",
  className = "",
  onClick = null,
}) => {
  if (type === "anchor") {
    return (
      <AnchorLink
        href={link}
        className={className}
        target={target}
        offset="80"
        onClick={onClick}
      >
        {text}
      </AnchorLink>
    )
  }
  return (
    <Link to={link} className={className} target={target} onClick={onClick}>
      {text}
    </Link>
  )
}
export default Anchor
