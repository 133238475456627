import React from "react"
import { Button } from "reactstrap"
import PropTypes from "prop-types"
import classNames from "classnames"
import { Link } from "gatsby"

const PrimaryButton = ({ text, alwaysPrimary, link, typeBtn }) => {
  return (
    <>
      <Button
        outline
        color="secondary"
        className={classNames({
          "primary-button-mobile d-block d-sm-none": !alwaysPrimary,
          "primary-button-mobile w-auto mx-auto d-block": alwaysPrimary,
        })}
      >
        {text}
      </Button>
      {!alwaysPrimary && (
        <Button
          outline
          color="secondary"
          className="primary-button-web d-none d-sm-block"
          type={typeBtn}
        >
          <Link to={link}>{text}</Link>
        </Button>
      )}
    </>
  )
}

PrimaryButton.propTypes = {
  text: PropTypes.string.isRequired,
  alwaysPrimary: PropTypes.bool,
  link: PropTypes.string.isRequired,
}

PrimaryButton.defaultProps = {
  alwaysPrimary: false,
  link: '#'
}

export default PrimaryButton
