import React from "react"
import { graphql } from "gatsby"
import { Col, Row } from "reactstrap"
import { useMediaQueries } from "@react-hook/media-query"
import BackgroundVideo from "react-background-video-player"

import RenderBlock from "components/ui/block-text"
import AnchorLink from "components/ui/anchor-link"

const Hero = ({ id, title, description, cta, videoWeb, videoMobile }) => {
  const {
    matches: { isMobile },
  } = useMediaQueries({
    isMobile: "only screen and (max-width: 992px)",
  })

  return (
    <section className="home-banner" id={id}>
      <div className="container">
        {!isMobile && (
          <BackgroundVideo src={videoWeb} loop autoPlay volume={0} />
        )}
        <Row className="mx-0 h-100">
          {isMobile && (
            <Col sm="12">
              <BackgroundVideo
                src={videoMobile}
                loop
                autoPlay
                volume={0}
                className="video-mobile"
              />
            </Col>
          )}
          <Col
            sm="12"
            md="12"
            lg="4"
            xl="4"
            className="d-flex align-items-center"
          >
            <div>
              <RenderBlock data={title} className="title" />
              <RenderBlock data={description} />
              {cta?.enable && (
                <AnchorLink
                  link={cta?.link}
                  text={cta?.text}
                  type={cta?.type}
                  target={cta?.target}
                  className="primary-button-web w-auto mx-auto"
                />
              )}
            </div>
          </Col>
          {!isMobile && <Col sm="12" md="12" lg="7" xl="7" />}
        </Row>
        {!isMobile && (
          <div className="scroll-container">
            <div className="scroll-content">
              <div className="arrow" />
              <span>Scroll</span>
            </div>
            <div className="scroll-circle" />
          </div>
        )}
      </div>
    </section>
  )
}

export default Hero

export const HeroSection = graphql`
  fragment HeroSection on SanitySectionHero {
    enable
    banner {
      enable
      id
      position
      style
      _rawTitle
      _rawExcerpt
      cta {
        ...ctaComponent
      }
      videoWeb
      videoMobile
    }
  }
`
