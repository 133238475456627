import React from "react"
import { graphql } from "gatsby"
import { Col, Row } from "reactstrap"

import RenderBlock from "../ui/block-text"
import Image from "../ui/image"

const OurTeams = ({ id, title, items }) => {
  const RenderItems = () => {
    return items?.map(item => (
      <Col
        sm="12"
        md="4"
        lg="4"
        xl="4"
        className="d-flex align-items-center justify-content-center"
        key={item._key}
      >
        <div className="px-xs-4 px-2 w-100">
          <Image data={item?._rawThumbnail} className="w-100 img-fluid" />
        </div>
      </Col>
    ))
  }

  return (
    <section className="our-team" id={id}>
      <div className="container">
        <Row className="mx-0">
          <Col sm="12">
            <RenderBlock data={title} line />
          </Col>
          <RenderItems />
          <Col
            sm="12"
            md="4"
            lg="4"
            xl="4"
            className="d-flex align-items-center justify-content-center"
          >
            <div className="px-4 d-flex justify-content-center">
              <button className="see-all">
                See <br /> all
              </button>
            </div>
          </Col>
        </Row>
      </div>
    </section>
  )
}

export default OurTeams

export const OurTeamsSection = graphql`
  fragment OurTeamsSection on SanitySectionOurTeams {
    enable
    _rawTitle
    items {
      enable
      _key
      _rawTitle
      _rawThumbnail
    }
  }
`
